/**
* Generated automatically at built-time (2024-10-08T07:37:24.366Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "mquvee-winekeeper-wine-cooler",templateKey: "sites/71-dabb68bb-23fd-4ac8-9680-23c84b7abdd5"};