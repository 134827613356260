/**
* Generated automatically at built-time (2024-10-08T07:37:24.378Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "wine-cooler-black-friday",templateKey: "sites/71-fb2a4320-c6ab-4650-ade9-80c0a1ee2dfc"};