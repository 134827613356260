/**
* Generated automatically at built-time (2024-10-08T07:37:24.340Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "mquvee-velvet-wine-cabinet",templateKey: "sites/71-efd8f461-1026-4953-95c1-ed863a4b0f65"};